import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';
import { onMounted } from "vue";
export default {
  name: "Contact",
  components: {
    Document
  },
  setup() {
    const setElAsideStyle = function () {
      let viewHeight = window.innerHeight;
      let viewWidth = window.innerWidth;
      if (viewHeight > viewWidth) {
        let elAsideClass = document.getElementsByClassName("el-aside");
        for (let i = 0; i < elAsideClass.length; i++) {
          let asideStyle = "display: none;";
          elAsideClass[i].setAttribute('style', asideStyle);
        }
      }
    };
    onMounted(() => {
      setElAsideStyle();
      // 监听浏览器高度变化
      window.onresize = () => {
        setElAsideStyle();
      };
    });
  }
};